import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

const Image = ({ src, alt, srcSet, isLazy = true, ...rest }) => {
    const data = useStaticQuery(graphql`
        {
            images: allFile(
                filter: { internal: { mediaType: { regex: "/image/" } } }
            ) {
                edges {
                    node {
                        relativePath
                        extension
                        publicURL
                        childImageSharp {
                            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    `);

    const match = useMemo(
        () => data.images.edges.find(({ node }) => src === node.relativePath),
        [data, src]
    );

    if (!match) {
        return (
            <img
                src={src}
                {...rest}
                style={{ zIndex: 1 }}
                alt={alt}
                srcSet={srcSet}
                loading={isLazy ? "lazy" : null}
            />
        );
    }

    const { node: { childImageSharp, publicURL, extension } = {} } = match;

    if (extension === "svg" || !childImageSharp) {
        return (
            <img
                src={publicURL}
                {...rest}
                style={{ zIndex: 1 }}
                alt={alt}
                srcSet={srcSet}
                loading={isLazy ? "lazy" : null}
            />
        );
    }
    return (
        <GatsbyImage
            image={childImageSharp.gatsbyImageData}
            {...rest}
            srcSet={srcSet}
            alt={alt}
            fadeIn="false"
            loading="eager"
        />
    );
};

Image.propTypes = {
    src: PropTypes.string.isRequired,
};

export default Image;
